// type:input、checkbox、tag、select、textarea、custom_input、
import { CSFMU_B } from "./typeConfig/CSFMU_B";
import { CSFMU_S } from "./typeConfig/CSFMU_S";
import { EHXTO } from "./typeConfig/EHXTO";
import { FANDV } from "./typeConfig/FANDV";
import { FMU_D } from "./typeConfig/FMU_D";
import { HEXT } from "./typeConfig/HEXT";
import { Liquid_EXT_B } from "./typeConfig/Liquid_EXT_B";
import { Liquid_EXT_S } from "./typeConfig/Liquid_EXT_S";
import { OEXT } from "./typeConfig/OEXT";
import { PCG_CSFMU_B } from "./typeConfig/PCG_CSFMU_B";
import { PCG_CSFMU_S } from "./typeConfig/PCG_CSFMU_S";
import { PCG } from "./typeConfig/PCG";
import { REXT } from "./typeConfig/REXT";
import { SDNP } from "./typeConfig/SDNP";
import { SDNP_L } from "./typeConfig/SDNP_L";
import { SHXTO } from "./typeConfig/SHXTO";

// import { CSFMU_B_TESTDATA } from "./typeConfig/CSFMU_B_TESTDATA";

// export const typeTestData = [
//     {
//         type: "CSFMU-B",
//         data: CSFMU_B_TESTDATA
//     }
// ]

export const typeData = [
    {
        type: "CSFMU-B",
        data: CSFMU_B
    },
    {
        type: "CSFMU-S",
        data: CSFMU_S
    },
    {
        type: "EHXTO",
        data: EHXTO
    },
    {
        type: "F&V",
        data: FANDV
    },
    {
        type: "FMU-D",
        data: FMU_D
    },
    {
        type: "HEXT",
        data: HEXT
    },
    {
        type: "Liquid EXT-B",
        data: Liquid_EXT_B
    },
    {
        type: "Liquid EXT-S",
        data: Liquid_EXT_S
    },
    {
        type: "OEXT",
        data: OEXT
    },
    {
        type: "PCG-CSFMU-B",
        data: PCG_CSFMU_B
    },
    {
        type: "PCG-CSFMU-S",
        data: PCG_CSFMU_S
    },
    {
        type: "PCG",
        data: PCG
    },
    {
        type: "REXT",
        data: REXT
    },
    {
        type: "SDNP-L",
        data: SDNP_L
    },
    {
        type: "SDNP",
        data: SDNP
    },
    {
        type: "SHXTO",
        data: SHXTO
    }
]

// 数据枚举
export const DataKeys = {
    BASE_INFO: 'base_info',
    GENERAL_SPECIFICATION: 'general_specification',
    ANALYSIS: 'analysis',
    MICROBIOLOGICAL_TESTS: 'microbiological_tests',
    FATTY_ACID: 'fatty_acid',
    OTHER_NOTES: 'other_notes'
};

export const config = {
    // 使用枚举来获取不同类型的 data
    getTypeData(type, key) {
        // 确保 key 是枚举中的一个有效值
        if (!Object.values(DataKeys).includes(key)) {
            console.error(`Invalid key: ${key}. Valid keys are:`, Object.values(DataKeys));
            return null;
        }

        // 假设 `typeData` 已经被定义并且是可访问的
        const item = typeData.find(item => item.type === type);

        if (!item) {
            console.error(`No data found for type: ${type}`);
            return null;
        }

        const data = item.data[key];
        return data;
    },
    getFirstLayerAttribute(type) {
        const item = typeData.find(item => item.type === type);
        if (!item) {
            console.error(`No data found for type: ${type}`);
            return null;
        }
        const firstLevelKeys = Object.keys(item.data);
        // console.info('firstLevelKeys:', firstLevelKeys);
        return firstLevelKeys;
    },
    // 使用枚举来获取不同类型的 data
    // getTestTypeData(type, key) {
    //     // 确保 key 是枚举中的一个有效值
    //     if (!Object.values(DataKeys).includes(key)) {
    //         console.error(`Invalid key: ${key}. Valid keys are:`, Object.values(DataKeys));
    //         return null;
    //     }

    //     // 假设 `typeTestData` 已经被定义并且是可访问的
    //     const item = typeTestData.find(item => item.type === type);

    //     if (!item) {
    //         console.error(`No data found for type: ${type}`);
    //         return null;
    //     }

    //     const data = item.data[key];
    //     return data;
    // },
};