<template>
  <el-select
    v-model="localValue"
    :placeholder="'Please Select ' + item.label"
    :disabled="item.disabled"
    @change="handleEvent"
    style="width: 100%"
  >
    <el-option
      v-for="(option, index) in item.options_value"
      :key="index"
      :label="setupOptionValue(option.label)"
      :value="option.label"
      :disabled="option.disabled"
    ></el-option>
  </el-select>
</template>

<script>
import { EventBus } from "../config/eventBus";
export default {
  props: {
    item: Object,
    value: String,
  },
  data() {
    return {
      localValue: this.value // 将 prop 赋值给 data 属性
    };
  },
  watch: {
    value(newVal) {
      this.localValue = newVal; // 当 prop 变化时，同步到 data 属性
    },
    localValue(newVal) {
      this.item.value=newVal;
      this.$emit('input', newVal); // 当 data 属性变化时，同步到父组件
    }
  },
  methods: {
    setupOptionValue(val){
      
      if(this.item.label==="Shelflife Number"){

        return val+" months";
      }
      return val;
    },
    handleEvent() {
      if(this.item.eventHandle){
        console.info('event')
        EventBus.$emit("productTypeChangeEvent", {
          type: this.localValue
        });
      }
      
    }
  },
  created(){
    this.localValue = this.item.value;
  }
};
</script>
