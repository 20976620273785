import * as defaultData from "../../data/DefaultData";

export const PCG = {
  base_info: {
    product_type: {
      type: "select",
      label: "Product Type",
      tips_value: "",
      options_value: defaultData.ProductTypeData,
      value: "",
      disabled: false,
      eventHandle: "productTypeChange",
    },
    product_name: {
      type: "input",
      label: "Product Name",
      tips_value: "",
      value: "",
      disabled: false,
    },
    item_code: {
      type: "input",
      label: "Item Code",
      tips_value: "",
      value: "",
      disabled: false,
    },
    product_description: {
      type: "textarea",
      label: "Product Description",
      tips_value: "",
      value: "",
      disabled: false,
    },
    version_number: {
      type: "input",
      label: "Version Number",
      tips_value: "",
      value: "",
      disabled: false,
    },
    physical_properties: {
      type: "select",
      label: "Physical Properties",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
    },
    confidential: {
      type: "checkbox",
      label: "Confidential",
      tips_value: false,
      value: "",
      disabled: false,
      checked: false,
    },
    custom_formula: {
      type: "checkbox",
      label: "Custom Formula",
      tips_value: false,
      value: "",
      disabled: false,
      checked: false,
    },
    document_notes: {
      type: "input",
      label: "Document Notes",
      tips_value: "",
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    ingredients: {
      type: "tag",
      label: "Ingredients",
      tips_value: [],
      value: "",
      disabled: false,
    },
    common_other_name: {
      type: "tag",
      label: "Common Other Name",
      tips_value: [],
      value: "",
      disabled: false,
    },
    inci_name: {
      type: "tag",
      label: "Inci Name",
      tips_value: [],
      value: "",
      disabled: false,
    },
    botanical_name: {
      type: "tag",
      label: "Botanical Name",
      tips_value: [],
      value: "",
      disabled: false,
    },
    part_used: {
      type: "tag",
      label: "Part Used",
      tips_value: [],
      value: "",
      disabled: false,
    },
    percentage: {
      type: "tag",
      label: "Percentage",
      tips_value: [],
      value: "",
      disabled: false,
    },
    product_grade: {
      type: "input",
      label: "Product Grade",
      tips_value: "Personal Care Grade",
      value: "",
      disabled: false,
    },
    country_of_origin: {
      type: "input",
      label: "Country Of Origin",
      tips_value: "",
      value: "",
      disabled: false,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    extraction_ratio: {
      type: "input",
      label: "Extraction Ratio",
      tips_value: "",
      value: "",
      disabled: false,
    },
    important_constitue_in_plant: {
      type: "input",
      label: "Important Constitue In Plant",
      tips_value: "",
      value: "",
      disabled: false,
    },
    appearance: {
      type: "input",
      label: "Appearance",
      tips_value: "Powder",
      value: "",
      disabled: false,
    },
    solubility: {
      type: "input",
      label: "Solubility",
      tips_value: "Water soluble/dispersible",
      value: "",
      disabled: false,
    },
    homogeneity: {
      type: "input",
      label: "Homogeneity",
      tips_value: "Completely homogenous",
      value: "",
      disabled: false,
    },
    extraction_process: {
      type: "input",
      label: "Extraction Process",
      tips_value:
        "Controlled-temperature water extraction process, spray dried.",
      value: "",
      disabled: false,
    },
    processing_aid: {
      type: "input",
      label: "Processing Aid",
      tips_value: "",
      value: "",
      disabled: false,
    },
    solvent_for_extraction: {
      type: "input",
      label: "Solvent For Extraction",
      tips_value: "Water",
      value: "",
      disabled: false,
    },
    carrier: {
      type: "input",
      label: "Carrier",
      tips_value: "None",
      value: "",
      disabled: false,
    },
    pesticides: {
      type: "input",
      label: "Pesticides",
      tips_value: "",
      value: "",
      disabled: false,
    },
    certification: {
      type: "input",
      label: "Certification",
      tips_value: "Kosher certified, HALAL certified",
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    standard1: {
      type: "input",
      label: "Standard1",
      tips_value: { specification: "", test_method: "DracoUV-" },
      value: "",
      disabled: false,
    },
    standard2: {
      type: "input",
      label: "Standard2",
      tips_value: { specification: "", test_method: "DracoHPLC-" },
      value: "",
      disabled: false,
    },
    standard3: {
      type: "input",
      label: "Standard3",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
    },
    mesh_size: {
      type: "select",
      label: "MeshSizeOptionsData",
      tips_value: "",
      options_value: defaultData.MeshSizeOptionsData,
      value: "",
      disabled: false,
    },
    loss_on_drying: {
      type: "input",
      label: "Loss On Drying",
      tips_value: { specification: "<6%", test_method: "Draco-1.1.1.0" },
      value: "",
      disabled: false,
    },
    bulk_density: {
      type: "input",
      label: "Bulk Density",
      tips_value: { specification: "0.25-0.75 g/mL", test_method: "USP <616>" },
      value: "",
      disabled: false,
    },
    identification: {
      type: "input",
      label: "Identification",
      tips_value: { specification: "Conforms", test_method: "HPTLC" },
      value: "",
      disabled: false,
    },
    total_heavy_metals: {
      type: "input",
      label: "Total Heavy Metals",
      tips_value: { specification: "<10 ppm", test_method: "USP<233>" },
      value: "",
      disabled: false,
    },
    arsenic: {
      type: "input",
      label: "Arsenic",
      tips_value: { specification: "", test_method: "USP<232>" },
      value: "",
      disabled: false,
    },
    cadmium: {
      type: "input",
      label: "Cadmium",
      tips_value: { specification: "", test_method: "USP<232>" },
      value: "",
      disabled: false,
    },
    lead: {
      type: "input",
      label: "Lead",
      tips_value: { specification: "", test_method: "USP<232>" },
      value: "",
      disabled: false,
    },
    mercury: {
      type: "input",
      label: "Mercury",
      tips_value: { specification: "", test_method: "USP<232>" },
      value: "",
      disabled: false,
    },
    color: {
      type: "input",
      label: "Color",
      tips_value: {
        specification: "Compares to standard",
        test_method: "Visual",
      },
      value: "",
      disabled: false,
    },
    odor: {
      type: "input",
      label: "Odor",
      tips_value: {
        specification: "Compares to standard",
        test_method: "Organoleptic",
      },
      value: "",
      disabled: false,
    },
    taste: {
      type: "input",
      label: "Taste",
      tips_value: {
        specification: "Compares to standard",
        test_method: "Organoleptic",
      },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "input",
      label: "Total Plate Count",
      tips_value: { specification: "<100 cfu/g", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    yeast_and_mold: {
      type: "input",
      label: "Yeast And Mold",
      tips_value: { specification: "<100 cfu/g", testMethod: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    ecoli: {
      type: "input",
      label: "Ecoli",
      tips_value: { specification: "Negative", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    salmonella: {
      type: "input",
      label: "Salmonella",
      tips_value: { specification: "Negative", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    staph_aureus: {
      type: "input",
      label: "Staph Aureus",
      tips_value: { specification: "Negative", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    total_coliforms: {
      type: "input",
      label: "Total Coliforms",
      tips_value: { specification: "<10 MPN/g", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "Shelflife Number",
      tips_value: "",
      value: "",
      disabled: false,
    },
    shelf_life: {
      type: "input",
      label: "Shelf Life",
      tips_value: ". Product is sensitive to heat and moisture.",
      value: "",
      disabled: false,
    },
    storage: {
      type: "input",
      label: "Storage",
      tips_value: "Preserve in airtight containers in a cool area, dry area.",
      value: "",
      disabled: false,
    },
    package_info: {
      type: "input",
      label: "Package Info",
      tips_value: "",
      value: "",
      disabled: false,
    },
    notes: {
      type: "input",
      label: "Notes",
      tips_value: "",
      value: "",
      disabled: false,
    },
  },
};
