import * as defaultData from "../../data/DefaultData";

export const EHXTO = {
  base_info: {
    product_type: {
      type: "select",
      label: "Product Type",
      tips_value: "",
      options_value: defaultData.ProductTypeData,
      value: "",
      disabled: false,
      eventHandle: "productTypeChange",
    },
    product_name: {
      type: "input",
      label: "Product Name",
      tips_value: "",
      value: "",
      disabled: false,
    },
    item_code: {
      type: "input",
      label: "Item Code",
      tips_value: "",
      value: "",
      disabled: false,
    },
    product_description: {
      type: "textarea",
      label: "Product Description",
      tips_value: "",
      value: "",
      disabled: false,
    },
    version_number: {
      type: "input",
      label: "Version Number",
      tips_value: "",
      value: "",
      disabled: false,
    },
    processing: {
      type: "input",
      label: "Processing",
      tips_value: "",
      value: "",
      disabled: false,
    },
    physical_properties: {
      type: "select",
      label: "Physical Properties",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
    },
    confidential: {
      type: "checkbox",
      label: "Confidential",
      tips_value: false,
      value: "",
      disabled: false,
      checked: false,
    },
    custom_formula: {
      type: "checkbox",
      label: "Custom Formula",
      tips_value: false,
      value: "",
      disabled: false,
      checked: false,
    },
    document_notes: {
      type: "input",
      label: "Document Notes",
      tips_value: "",
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    common_other_name: {
      type: "tag",
      label: "Common Other Name",
      tips_value: [],
      value: "",
      disabled: false,
    },
    inci_name: {
      type: "tag",
      label: "Inci Name",
      tips_value: [],
      value: "",
      disabled: false,
    },
    botanical_name: {
      type: "tag",
      label: "Botanical Name",
      tips_value: [],
      value: "",
      disabled: false,
    },
    part_used: {
      type: "tag",
      label: "Part Used",
      tips_value: [],
      value: "",
      disabled: false,
    },
    percentage: {
      type: "tag",
      label: "Percentage",
      tips_value: [],
      value: "",
      disabled: false,
    },
    country_of_origin: {
      type: "input",
      label: "Country Of Origin",
      tips_value: "China",
      value: "",
      disabled: false,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    important_constitue_in_plant: {
      type: "input",
      label: "Important Constitue In Plant",
      tips_value: "",
      value: "",
      disabled: false,
    },
    appearance: {
      type: "input",
      label: "Appearance",
      tips_value: "Oil",
      value: "",
      disabled: false,
    },
    homogeneity: {
      type: "input",
      label: "Homogeneity",
      tips_value: "Completely homogenous",
      value: "",
      disabled: false,
    },
    extraction_process: {
      type: "input",
      label: "Extraction Process",
      tips_value: "",
      value: "",
      disabled: false,
    },
    solvent_for_extraction: {
      type: "input",
      label: "Solvent For Extraction",
      tips_value: "",
      value: "",
      disabled: false,
    },
    solubility: {
      type: "input",
      label: "Solubility",
      tips_value: "Oil soluble",
      value: "",
      disabled: false,
    },
    carrier: {
      type: "input",
      label: "Carrier",
      tips_value: "",
      value: "",
      disabled: false,
    },
    pesticides: {
      type: "input",
      label: "Pesticides",
      tips_value: "Conforms to USP<565>",
      value: "",
      disabled: false,
    },
    certification: {
      type: "input",
      label: "Certification",
      tips_value: "",
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    moisture: {
      type: "input",
      label: "Moisture",
      tips_value: { specification: "%", test_method: " AOCS Ca 2d-25 " },
      value: "",
      disabled: false,
    },
    specific_gravity: {
      type: "input",
      label: "Specific Gravity",
      tips_value: {
        specification: "1.0200~1.0430 g/mL",
        test_method: "USP<841>",
      },
      value: "",
      disabled: false,
    },
    optical_rotation: {
      type: "input",
      label: "Optical Rotation",
      tips_value: { specification: "-3°~+1°", test_method: "USP<781>" },
      value: "",
      disabled: false,
    },
    refractive_index: {
      type: "input",
      label: "Refractive Index",
      tips_value: { specification: "1.5750~1.6010", test_method: "USP<831>" },
      value: "",
      disabled: false,
    },
    total_heavy_metals: {
      type: "input",
      label: "Total Heavy Metals",
      tips_value: { specification: "<20 ppm", test_method: "USP<233>" },
      value: "",
      disabled: false,
    },
    cadmium: {
      type: "input",
      label: "Cadmium",
      tips_value: { specification: "<0.5 ppm", test_method: "AOAC 986.15" },
      value: "",
      disabled: false,
    },
    lead: {
      type: "input",
      label: "Lead",
      tips_value: { specification: "<0.5 ppm", test_method: "AOCS Ca 18c-91" },
      value: "",
      disabled: false,
    },
    mercury: {
      type: "input",
      label: "Mercury",
      tips_value: { specification: "<0.5 ppm", test_method: "AOAC 971.21" },
      value: "",
      disabled: false,
    },
    arsenic: {
      type: "input",
      label: "Arsenic",
      tips_value: { specification: "<0.5 ppm", test_method: "AOAC 986.15" },
      value: "",
      disabled: false,
    },
    color: {
      type: "input",
      label: "Color",
      tips_value: { specification: "", test_method: "Visual" },
      value: "",
      disabled: false,
    },
    odor: {
      type: "input",
      label: "Odor",
      tips_value: {
        specification: "Characteristic",
        test_method: "Organoleptic",
      },
      value: "",
      disabled: false,
    },
    taste: {
      type: "input",
      label: "Taste",
      tips_value: {
        specification: "Characteristic",
        test_method: "Organoleptic",
      },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "input",
      label: "Total Plate Count",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    yeast_and_mold: {
      type: "input",
      label: "Yeast And Mold",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    ecoli: {
      type: "input",
      label: "Ecoli",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    salmonella: {
      type: "input",
      label: "Salmonella",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    staph_aureus: {
      type: "input",
      label: "Staph Aureus",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    total_coliforms: {
      type: "input",
      label: "Total Coliforms",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "Shelflife Number",
      tips_value: "",
      value: "",
      disabled: false,
    },
    shelf_life: {
      type: "input",
      label: "Shelf Life",
      tips_value: ".",
      value: "",
      disabled: false,
    },
    storage: {
      type: "input",
      label: "Storage",
      tips_value: "Preserve in airtight containers in a cool area, dry area.",
      value: "",
      disabled: false,
    },
    package_info: {
      type: "input",
      label: "Package Info",
      tips_value: "",
      value: "",
      disabled: false,
    },
    notes: {
      type: "input",
      label: "Notes",
      tips_value: "",
      value: "",
      disabled: false,
    },
  },
};
