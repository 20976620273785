<template>
  <div>
    <div
      v-for="(item, index) in localCustomFields"
      :key="item.key"
      class="custom-field"
    >
      <el-row>
        <!-- 左侧第一个 input 和右侧第一个 input 同步 -->
        <el-col :span="6">
          <!-- 左侧第一个 input 和右侧第一个 input 绑定 fields 属性 -->

          <el-input
            v-model="item.fields"
            :disabled="getItemDisabled()"
            placeholder="Please Input"
            @change="inputChange('fields', item, $event, index)"
          ></el-input>
        </el-col>

        <!-- 根据方向绑定不同的字段 -->
        <el-col :span="18" v-if="direction === 'left'">
          <!-- 左侧第二个 input 绑定 specification -->
          <el-input
            v-model="item.specification"
            :disabled="item.disabled"
            placeholder="Please Input"
            clearable
            @change="inputChange('specification', item, $event, index)"
          ></el-input>
        </el-col>
        <el-col :span="15" v-else-if="direction === 'right'">
          <!-- 右侧第二个 input 绑定 test_method -->
          <el-input
            v-model="item.test_method"
            :disabled="item.disabled"
            placeholder="Please Input"
            clearable
            @change="inputChange('test_method', item, $event, index)"
          ></el-input>
        </el-col>
        <el-col :span="15" v-else>
          <!-- 右侧第二个 input 绑定 test_method -->

          <el-input
            v-model="item.value"
            :disabled="item.disabled"
            placeholder="Please Input"
            clearable
            @change="inputChange('value', item, $event, index)"
          ></el-input>
        </el-col>

        <!-- 右侧列显示添加和删除按钮 -->
        <el-col :span="3" v-if="direction != 'left'">
          <el-button
            type="primary"
            icon="el-icon-circle-plus"
            circle
            :disabled="item.disabled"
            @click="addClick(item)"
          ></el-button>
          <el-button
            type="danger"
            :disabled="item.disabled"
            icon="el-icon-delete"
            circle
            @click="removeClick(index)"
          ></el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    direction: String, // 用于判断是左侧还是右侧
    pageStatus: String,
  },
  data() {
    return {
      localCustomFields: [], // 复制 props 到 data
      tmpItem: {},
      tmpVal: "",
      updateIndex: -1,
    };
  },
  watch: {
    item: {
      handler(newItem) {
        // this.localCustomFields = newItem.value;
        if (typeof newItem !== "undefined") {
          let isAddOrDelete = localStorage.getItem("isAddOrDelete");
          // console.info("isAddOrDelete:", isAddOrDelete);
          if (isAddOrDelete == "true") {
            this.localCustomFields = newItem.value;
          } else if (this.updateIndex != -1) {
            if (typeof newItem.value !== "undefined") {
              this.tmpItem = newItem.value[0];
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.localCustomFields = [];
    // console.info('this.item.value:',this.item.value)
    if (this.item.value) {
      for (let i = 0; i < this.item.value.length; i++) {
        let item = this.item.value[i];
        let val = item.value;

        // 根据 direction 决定绑定 specification 还是 test_method
        if (this.direction === "left") {
          val = item.specification || ""; // 确保 specification 初始化
        } else if (this.direction === "right") {
          val = item.test_method || ""; // 确保 test_method 初始化
        } else {
          val = item.value || "";
        }

        this.localCustomFields.push({
          key: `${this.item.attr}_${i}`, // 使用 attr 和 index 生成唯一 key
          type: "custom_input",
          label: "Custom #:",
          tips_value: "",
          value: val,
          disabled: this.item.disabled,
          fields: item.fields || "", // 确保 fields 初始化
          specification: item.specification || "", // 初始化 specification
          test_method: item.test_method || "", // 初始化 test_method
          attr: this.item.attr,
        });
      }
    }

    if (this.localCustomFields.length == 0) {
      this.initData();
    }
  },
  methods: {
    inputChange(key, item, val, index) {
      localStorage.setItem("isAddOrDelete", false);
      this.tmpItem = item;
      if (key == "specification") {
        item.specification = val;
      } else if (key == "test_method") {
        item.test_method = val;
      }
      item.attr = this.item.attr;
      let direction = this.direction;
      this.updateIndex = index;
      //更新当前输入框的值
      this.$emit("updateCustomField", { key, item, val, direction });
    },
    getItemDisabled() {
      if(this.pageStatus=='Detail'){
        return true;
      }
      if (this.direction == "right") {
        return true;
      }
      return false;
    },
    initData() {
      this.localCustomFields.push({
        key: `${this.item.attr}_0`, // 初始化时确保 key 唯一
        type: "custom_input",
        label: "Custom #:",
        tips_value: "",
        value: this.item.value || "",
        disabled: this.item.disabled,
        fields: this.item.fields || "", // 确保 fields 初始化
        specification: this.item.specification || "", // 初始化 specification
        test_method: this.item.test_method || "", // 初始化 test_method
        attr: this.item.attr,
      });
    },
    addClick(item) {
      localStorage.setItem("isAddOrDelete", true);
      let attr = this.item.attr;
      this.$emit("addCustomField", item, this.direction, attr);
    },
    removeClick(index) {
      localStorage.setItem("isAddOrDelete", true);
      let attr = this.item.attr;
      if (this.localCustomFields.length > 1) {
        this.$emit("removeCustomField", this.direction, index, attr);
      }
    },
  },
};
</script>

<style>
</style>