<template>
  <div>
    <el-button
      type="primary"
      @click="recoverHandle"
      :disabled="optionButtonFlag != true"
      >Recover From This Version</el-button
    >
    <el-button
      type="warning"
      @click="addVerifyListHandle"
      :disabled="optionButtonFlag"
      >Add Verify List</el-button
    >
    <el-button
      type="success"
      @click="saveHandle"
      :loading="saveLoading"
      :disabled="optionButtonFlag"
      >Save</el-button
    >
    <el-button
      type="danger"
      @click="verifyHandle"
      :loading="verifyLoading"
      :disabled="optionButtonFlag"
      >Verify</el-button
    >
    <el-button
      type="primary"
      @click="previewHandle"
      :loading="previewPdfLoading"
      >Preview PDF</el-button
    >
    <el-button @click="backHomeClick">Back Home</el-button>
  </div>
</template>
<script>
import * as StructConvertUtils from "./config/structConvertUtils";
import dayjs from "dayjs";
export default {
  props: {
    pageStatus: String,
    isPrintSignature: Boolean,
  },
  computed: {
    optionButtonFlag() {
      return this.pageStatus == "Detail";
    },
  },
  data() {
    return {
      saveLoading: false,
      verifyLoading: false,
      previewPdfLoading: false,
    };
  },
  inject: [
    "getFormDataMethod",
    "loadDetailDataMethod",
    "loadRecoverDataMethod",
  ],
  methods: {
    addVerifyListHandle() {
      this.saveLoading = true;
      this.$confirm("Add To Verify List？", "Tips", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        let data = this.getFormDataMethod();
        let res = StructConvertUtils.transformSaveData(data);
        let params = this.getSaveData(res);
        params.revNumber = data.revisionData.length;

        this.$api.handleSpec
          .verifyListNewSpec(params)
          .then(() => {
            this.saveLoading = false;
            this.$message({
              type: "success",
              message: "Already added to the Verify list",
            });
          })
          .catch((err) => {
            this.saveLoading = false;
            console.log(err);
          });
      });
    },
    getSaveData(res) {
      const saveData = {
        id: this.$route.query.id,
        itemCode: res.base_info["item_code"],
        productType: res.base_info["product_type"],
        productName: res.base_info["product_name"],
        physicalProperties: res.base_info["physical_properties"],
        countryOfOrigin: res.general_specification["country_of_origin"],
        botanicalName: res.general_specification["botanical_name"].toString(),
        productDescription: res.base_info["product_description"],
        shelflifeNumber: res.other_notes["shelflife_number"],
        jsonValue: JSON.stringify(res),
      };

      // console.info("saveData:", saveData);
      return saveData;
    },

    saveHandle() {
      let data = this.getFormDataMethod();
      console.info("-------------------------")
      console.info('转前data：',data)
      let res = StructConvertUtils.transformSaveData(data);
      console.info('ddddddd')
      let baseInfoData = res.base_info;

      //处理 customefield


      let saveData = this.getSaveData(res);
      // console.info('data---:',data);
      console.info('res---:',res);
      // console.info("saveData--:",saveData);
      // 判断 productType 是否为空
      let msg = "";
      if (this.selectType == "") {
        msg = "Please Select Product Type!";
      }
      // 判断 itemCode 是否为空
      if (baseInfoData["item_code"] == "") {
        msg = "Please Input Item Code!";
      }
      if (msg != "") {
        this.$message({
          type: "error",
          message: msg,
        });
      }

      let params = { ...this.$route.query };
      // console.info("params:", params.id);
      this.saveLoading = true;
      console.info('saveData===:',saveData)
      if (params.id) {
        this.sendEdit(saveData);
      } else {
        saveData.id = params.id;
        this.sendAdd(saveData);
      }
    },
    setupCustomFields(data){
      console.info('dtaa:',data);
    },
    verifyHandle() {
      this.$confirm("Are You Sure About Verify?", "Tips", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        let data = this.getFormDataMethod();

        // 检查 revisionData
        let revision = data.revisionData[data.revisionData.length - 1];
        console.info("revision:", revision);
        let msg = "";
        if (revision.requestedBy.length == 0) {
          msg = "Requested By Can Not Be Empty!";
        } else if (revision.itemsRevised == 0) {
          msg = "Revised Notes Can Not Be Empty!";
        } else if (revision.editedBy == 0) {
          msg = "Edited By Can Not Be Empty!";
        } else if (revision.reason == 0) {
          msg = "Reason Can Not Be Empty!";
        }
        if (msg != "") {
          this.$message({
            type: "error",
            message: msg,
          });
          return;
        }
        revision.isComplete = true;
        let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
        revision.approvedBy = userInfo.username;
        revision.verifyName = userInfo.username;
        revision.verifyTime = dayjs().format("YYYY-MM-DD HH:mm:ss");

        let res = StructConvertUtils.transformSaveData(data);
        res.revision = data.revisionData;
        let saveData = this.getSaveData(res);
        saveData.revNumber = data.revisionData.length;
        // console.info('saveData-rev:',saveData);
        this.verifyLoading = true;

        this.verifyNewSpec(saveData);
      });
    },
    sendAdd(params) {
      this.$api.handleSpec
        .addNewSpecItem(params)
        .then(() => {
          this.saveLoading = false;
          this.$message({
            type: "success",
            message: "Add Success",
          });
        })
        .catch((err) => {
          this.saveLoading = false;
          console.log(err);
        });
    },
    sendEdit(params) {
      this.$api.handleSpec
        .editNewSpecItem(params)
        .then(() => {
          this.saveLoading = false;
          this.$message({
            type: "success",
            message: "Update Success",
          });
        })
        .catch((err) => {
          this.saveLoading = false;
          console.log(err);
        });
    },
    verifyNewSpec(params) {
      this.$api.handleSpec
        .verifyNewSpec(params)
        .then(() => {
          this.verifyLoading = false;
          this.loadDetailDataMethod();
          this.$message({
            type: "success",
            message: "Update Success",
          });
        })
        .catch((err) => {
          this.verifyLoading = false;
          console.log(err);
        });
    },
    previewHandle() {
      let data = this.getFormDataMethod();
      let res = StructConvertUtils.transformSaveData(data);

      // 添加 revisionData
      res.revision_info = data.revisionData[data.revisionData.length - 2];

      let saveData = this.getSaveData(res);
      saveData.verified_time = data.verified_time;
      saveData.rev_number = data.rev_number;

      let versionNumber = "preview";
      let previewPdf = true; //编辑状态为 true
      if (this.pageStatus == "Detail") {
        previewPdf = false;
        versionNumber = "V_" + data["revNumber"] + ".0";
      }
      let signatureName = "";
      if (this.isPrintSignature) {
        signatureName = "_signature";
      }
      let previewName = "";
      if (previewPdf) {
        previewName = "_preview";
      }

      //预览后端需要的字段为 json_value

      saveData.json_value = saveData.jsonValue;
      delete saveData.jsonValue;

      console.info("previewData:", saveData);
      let params = {
        language:"US",
        data: saveData,
        cutomer_signature: this.isPrintSignature,
        preview_pdf: previewPdf,
        file_name:
          saveData.itemCode + "_" + versionNumber + signatureName + previewName, //item_code+version+signature
        response_type: "text",
      };

      this.previewPdfLoading = false;
      this.$axios
        .post("https://spec.dracoherbs.com/flask/generate_spec", params, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          let fileUrl = "https://spec.dracoherbs.com/download/"+response.data.file_name;
          let encodeUrl = encodeURIComponent(fileUrl);
          let url =
            "https://ofd.xdocin.com/view?src=" +
            encodeUrl;
            console.info('url:',url)
          setTimeout(function () {
            this.previewPdfLoading = true;
            window.open(url);
          }, 200);
        })
        .catch((error) => {
          this.previewPdfLoading = true;
          console.error(error);
        });
    },
    backHomeClick() {
      window.history.go(-1);
    },
    recoverHandle() {
      this.$confirm("Are you sure to restore from this version?", "Tips", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.loadRecoverDataMethod();
      });
    },
  },
};
</script>