<template>
  <div>
    <el-form
      ref="baseInfoForm"
      size="medium"
      :model="localModel"
      label-width="200px"
    >
      <el-row>
        <el-col
          v-for="column in getColumns"
          :key="column.key"
          :span="column.span"
        >
          <el-form-item
            :label="formatTitle(item)"
            v-for="(item, key) in column.list"
            :key="key"
          >
            <component
              v-if="getShowControl(item)"
              :is="getComponentType(getArrayItemType(item))"
              :item="item"
              :pageStatus="pageStatus"
              :direction="column.key"
              :controlData="controlData"
              :value="localModel[item.value]"
              :customFields="getCustomFieldsForColumn(column.key)"
              @input="
                updateValue(
                  column.key,
                  getAttr(getArrayItemLabel(item)),
                  $event
                )
              "
              @addCustomField="handleAddCustomField"
              @updateCustomField="handleUpdateCustomField"
              @removeCustomField="handleRemoveCustomField"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import CustomField from "./components/CustomField.vue";
import TagComponents from "./components/TagComponents.vue";
import ElSelect from "./components/ElSelect.vue";
import ElInput from "./components/ElInput.vue";
import ElTextarea from "./components/ElTextarea.vue";
import ElCheckbox from "./components/ElCheckbox.vue";
// import * as TypeData from "./data/allTypeData";
import * as StructConvertUtils from "./config/structConvertUtils";
export default {
  components: {
    CustomField,
    TagComponents,
    ElSelect,
    ElInput,
    ElTextarea,
    ElCheckbox,
  },
  props: {
    controlData: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    pageStatus:{
      type: String,
      required: true
    }
  },
  inject: ["setCustomFieldsMethod"],
  data() {
    return {
      mappingModel: {},
      custom_fields: [
        {
          key: "base_info_custom_field_1",
          fields: "",
          value: "",
          disable: true,
        },
      ],
    };
  },
  computed: {
    localModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    getColumns() {
      const columns = [];
      if (this.controlData.row === 1) {
        columns.push({ key: "full", span: 24, list: this.controlData.list });
      } else if (this.controlData.row === 2) {
        columns.push({
          key: "left",
          span: 12,
          list: this.controlData.leftList,
        });
        columns.push({
          key: "right",
          span: 12,
          list: this.controlData.rightList,
        });
      }
      return columns;
    },
  },
  created() {
    // console.info('Mvalue:',this.value)
  },
  methods: {
    getShowControl(item) {
      let label = this.formatTitle(item);
      return label != "";
    },
    getArrayItemType(item) {
      if (item instanceof Array) {
        return item[0].type;
      }
      return item.type;
    },
    getArrayItemLabel(item) {
      if (item instanceof Array) {
        return item[0].label;
      }
      return item.label;
    },
    getArrayItemValue() {},
    formatTitle(item) {
      if(typeof(item.label) =="undefined"){
        return "";
      }
      let resTitle = StructConvertUtils.formatTitle(item.label);
      if (resTitle == "Notes") {
        return "";
      }
      return resTitle;
    },
    handleEvent(functionName) {
      this[functionName]();
    },
    getComponentType(type) {
      const types = {
        select: "ElSelect",
        input: "ElInput",
        textarea: "ElTextarea",
        checkbox: "ElCheckbox",
        tag: "TagComponents",
        custom_input: "CustomField",
      };
      return types[type] || "div";
    },
    getCustomFieldsForColumn(columnKey) {
      if (columnKey === "left") {
        return this.custom_fields.map((field) => ({
          fields: field.fields,
          specification: field.specification,
        }));
      } else if (columnKey === "right") {
        return this.custom_fields.map((field) => ({
          test_method: field.test_method,
        }));
      }
      return [];
    },
    getAttr(label) {
      return label.toLowerCase().replace(/ /g, "_");
    },
    updateValue(columnKey, label, value) {
      if (!this.localModel[columnKey]) {
        this.$set(this.localModel, columnKey, {});
      }
      this.$set(this.localModel[columnKey], label, value);

      // 联动逻辑：左侧第一个输入框联动右侧第一个输入框
      if (columnKey === "left" && label === "first_input_field") {
        this.updateValue("right", label, value);
      }
    },
    handleUpdateCustomField(params) {
      if (params.direction != "full") {
        const leftColumn = this.getColumns.find(
          (column) => column.key === "left"
        );

        let fieldsLeftObj = leftColumn.list.find(
          (obj) => obj.attr === params.item.attr
        );
        if (params.key == "specification") {
          fieldsLeftObj.value[0].specification = params.val;
        } else if (params.key == "test_method") {
          fieldsLeftObj.value[0].test_method = params.val;
        } else if (params.key == "fields") {
          fieldsLeftObj.value[0].fields = params.val;
        }
        console.info("fieldsLeftObj:", fieldsLeftObj);
      }else{
        const fullColumn = this.getColumns.find(
          (column) => column.key === "full"
        );
        let fieldsObj = fullColumn.list.custom_field.value;
        if(fieldsObj.length==1){
          fieldsObj[0].fields = params.item.fields;
          fieldsObj[0].value = params.val;
        }
        this.setCustomFieldsMethod(params,fieldsObj);
      }
    },
    handleAddCustomField(item, direction,attr) {
      // console.info("item:", item);
      // console.info("direction:", direction);

      const uniqueKey = `base_info_custom_field_${Date.now()}_${Math.random()}`;
      if (direction == "full") {
        const newField = {
          key: uniqueKey,
          fields: "",
          value: "",
        };

        const fullColumn = this.getColumns.find(
          (column) => column.key === "full"
        );
        let fieldsObjValue = fullColumn.list.custom_field.value;
        fieldsObjValue.push(newField);
      } else {
        //right
        const newLeftField = {
          key: uniqueKey,
          fields: "",
          specification: "",
          test_method: "",
        };
        const leftColumn = this.getColumns.find(
          (column) => column.key === "left"
        );
        let fieldsLeftObj = leftColumn.list.find(
          (obj) => obj.attr === attr
        );

        console.info("newLeftField:", newLeftField);
        fieldsLeftObj.value.push(newLeftField);
      }
    },
    handleRemoveCustomField(columnKey, index, attr) {
      if (columnKey == "full") {
        const Column = this.getColumns.find((column) => column.key === "full");
        if(attr=="custom_field_before_regular_data"){
          Column.list.custom_field_before_regular_data.value.splice(index, 1);
        }else{
          Column.list.custom_field.value.splice(index, 1);
        }
      } else if (columnKey === "right") {
        // 如果是右侧删除字段，联动左侧删除相应字段
        const leftColumn = this.getColumns.find(
          (column) => column.key === "left"
        );
        const rightColumn = this.getColumns.find(
          (column) => column.key === "right"
        );
        console.info('delete')

        if (leftColumn) {
          const fieldsObj = leftColumn.list.filter(
            (item) => item.attr === attr
          )[0];

          const fieldsRightObj = rightColumn.list.filter(
            (item) => item.attr === attr
          )[0];
          if (index != -1) {
            fieldsObj.value.splice(index, 1);
            fieldsRightObj.value.splice(index, 1);
          }
        }
      }
    }
  },
};
</script>
