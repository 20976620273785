<template>
  <div>
    <el-form :inline="true" class="demo-form-inline">
      <div v-for="(item, index) in revisionList" :key="index">
        <el-form-item label="Rev">
          {{ index + 1 }}
        </el-form-item>
        <el-form-item label="RevisionDate">
          <el-input
            v-model="item.revisionDate"
            placeholder="RevisionDate"
            disabled
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="RequestedBy">
          <el-input
            v-model="item.requestedBy"
            :disabled="optionButtonFlag(item)"
            placeholder="RequestedBy"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="Revised Notes">
          <el-input
            v-model="item.itemsRevised"
            :disabled="optionButtonFlag(item)"
            placeholder="Revised Notes"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="EditedBy">
          <el-input
            v-model="item.editedBy"
            :disabled="optionButtonFlag(item)"
            placeholder="EditedBy"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="Reason">
          <el-input
            v-model="item.reason"
            :disabled="optionButtonFlag(item)"
            placeholder="Reason"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="ApprovedBy">
          <el-input
            v-model="item.approvedBy"
            placeholder=""
            disabled
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item style="width: 120px">
          <el-button
            type="text"
            :disabled="item.isComplete"
            v-if="
              item.revisionDate &&
              item.requestedBy &&
              item.itemsRevised &&
              item.editedBy &&
              item.reason &&
              item.approvedBy
            "
            >Complete</el-button
          >
          &nbsp;&nbsp;
          <!-- <el-button
            type="text"
            v-if="!item.isComplete && revisionList.length > 1"
          >
            <i style="color: Red; font-size: 18px" class="el-icon-delete"></i>
          </el-button> -->
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    pageStatus:String,
    revisionList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  watch: {
    revisionList(newVal){
      console.info(newVal);
    }
  },
  created() {
    // console.warn("this.revisionList", this.revisionList);
    
  },
  methods: {
    optionButtonFlag(item){
      if(this.pageStatus == 'Detail'){
        return true;
      }
      return item.isComplete;
    }
  },
};
</script>